/*  
  #F2B933 : Yellow
  #2A2A2A : Black
  #F5F5F5 : White
*/

body {
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
  color: #222222;
}
